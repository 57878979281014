var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "page-properties" },
    [
      _c("tev-navbar", { attrs: { title: "Properties" } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                {
                  staticStyle: { "padding-left": "0px" },
                  attrs: {
                    "align-start": "",
                    "justify-start": "",
                    row: "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { "px-5": "", "pt-4": "", "pb-5": "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "align-center": "",
                            "justify-end": "",
                            row: "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "px-1": "",
                                "py-1": "",
                                "pb-5": "",
                              },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "prepend-inner-icon": _vm.subscriberId
                                    ? ""
                                    : "search",
                                  "append-icon": "",
                                  "hide-no-data": "",
                                  "single-line": "",
                                  "item-text": "description",
                                  "item-value": "id",
                                  items: _vm.subscribers,
                                  "search-input": _vm.searchSubscriber,
                                  flat: "",
                                  clearable: "",
                                  label: "Filter By Subscriber",
                                  "hide-details": "",
                                  box: "",
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.searchSubscriber = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.searchSubscriber = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ item, selected }) {
                                      return [
                                        _c("strong", {
                                          staticClass: "primary--text",
                                          domProps: {
                                            textContent: _vm._s(
                                              item.description
                                            ),
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.subscriberId,
                                  callback: function ($$v) {
                                    _vm.subscriberId = $$v
                                  },
                                  expression: "subscriberId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-flex", { attrs: { "pt-1": "" } }, [
                            _c("h4", { staticClass: "ma-0" }, [
                              _vm._v("PROPERTIES"),
                            ]),
                          ]),
                          !_vm.isLoading && _vm.total
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "text-md-right": "",
                                    "grey--text": "",
                                    "pr-3": "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Showing results\n              "
                                  ),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.startResult)),
                                  ]),
                                  _vm._v(" -\n              "),
                                  _c("strong", [_vm._v(_vm._s(_vm.endResult))]),
                                  _vm._v(" of\n              "),
                                  _c("strong", [_vm._v(_vm._s(_vm.total))]),
                                  (_vm.status.length &&
                                    _vm.status.length <
                                      _vm.statusTypes.length) ||
                                  _vm.subscriberId
                                    ? [
                                        _vm._v(
                                          "\n                filter by\n                "
                                        ),
                                        _vm.status.length &&
                                        _vm.status.length <
                                          _vm.statusTypes.length
                                          ? [
                                              _vm._v(
                                                "\n                  status\n                  "
                                              ),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(_vm.status.join(", "))
                                                ),
                                              ]),
                                              _vm.subscriberId
                                                ? [_vm._v("and")]
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                        _vm.subscriberId
                                          ? [
                                              _vm._v(
                                                "\n                  subscriber\n                  "
                                              ),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(_vm.subscriberName)
                                                ),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "text-xs-center pa-5" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c(
                              "v-list",
                              {
                                staticClass: "transparent",
                                attrs: { "two-line": "" },
                              },
                              [
                                _c("v-divider"),
                                _vm._l(_vm.properties, function (item, index) {
                                  return [
                                    _c(
                                      "v-list-tile",
                                      {
                                        key: index,
                                        attrs: {
                                          avatar: "",
                                          to: item.statusUrl,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-tile-avatar",
                                          [
                                            _c("Jazzicon", {
                                              attrs: {
                                                address: item.subscriberName,
                                                size: 40,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    item.propertyName
                                                      ? item.propertyName
                                                      : "Untitled"
                                                  ) +
                                                  "\n                      "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text text--lighten-1 ml-3 body-1",
                                                },
                                                [
                                                  item.lastSentInvitation
                                                    ? [
                                                        _vm._v(
                                                          "Last Invitation: " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                item.lastSentInvitation,
                                                                "from"
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ]),
                                            _c("v-list-tile-sub-title", [
                                              _vm._v(
                                                _vm._s(item.subscriberName)
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-tile-action",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "chip-fluid",
                                                attrs: {
                                                  color:
                                                    _vm.statusColors[
                                                      item.status
                                                    ],
                                                  "text-color": "white",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.status))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { key: "d" + index }),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm.totalPages > 1
                              ? _c(
                                  "div",
                                  { staticClass: "mt-3 text-xs-center" },
                                  [
                                    _c("v-pagination", {
                                      attrs: {
                                        length: _vm.totalPages,
                                        "total-visible": 7,
                                      },
                                      on: { input: _vm.changePage },
                                      model: {
                                        value: _vm.currentPage,
                                        callback: function ($$v) {
                                          _vm.currentPage = $$v
                                        },
                                        expression: "currentPage",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }