<template>
  <v-app class="page-properties">
    <tev-navbar title="Properties" />
    <v-content>
      <v-container fill-height fluid pa-0>

        <v-layout align-start justify-start row fill-height style="padding-left: 0px;">
          <v-flex px-5 pt-4 pb-5>
            <v-layout wrap align-center justify-end row>
              <v-flex xs12 px-1 py-1 pb-5>
                <v-autocomplete
                  v-model="subscriberId"
                  :prepend-inner-icon="subscriberId?'':'search'"
                  append-icon
                  hide-no-data
                  single-line
                  item-text="description"
                  item-value="id"
                  :items="subscribers"
                  :search-input.sync="searchSubscriber"
                  flat
                  clearable
                  label="Filter By Subscriber"
                  hide-details
                  box
                >
                  <template v-slot:selection="{ item, selected }">
                    <strong class="primary--text" v-text="item.description"></strong>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-flex pt-1>
                <h4 class="ma-0">PROPERTIES</h4>
              </v-flex>
              <v-flex text-md-right grey--text pr-3 v-if="!isLoading&&total">
                Showing results
                <strong>{{ startResult }}</strong> -
                <strong>{{ endResult }}</strong> of
                <strong>{{ total }}</strong>
                <template v-if="(status.length&&status.length<statusTypes.length)||subscriberId">
                  filter by
                  <template v-if="(status.length&&status.length<statusTypes.length)">
                    status
                    <strong>{{ status.join(', ') }}</strong>
                    <template v-if="subscriberId">and</template>
                  </template>
                  <template v-if="subscriberId">
                    subscriber
                    <strong>{{ subscriberName }}</strong>
                  </template>
                </template>
              </v-flex>
            </v-layout>
            <div class="text-xs-center pa-5" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
            <template v-if="!isLoading">
              <v-list two-line class="transparent">
                <v-divider></v-divider>
                <template v-for="(item, index) in properties">
                  <v-list-tile
                    v-bind:key="index"
                    avatar
                    :to="item.statusUrl"
                  >
                    <v-list-tile-avatar>
                      <Jazzicon :address="item.subscriberName" :size="40" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ item.propertyName? item.propertyName: 'Untitled' }}
                        <span class="grey--text text--lighten-1 ml-3 body-1">
                          <template
                            v-if="item.lastSentInvitation"
                          >Last Invitation: {{ item.lastSentInvitation | moment("from") }}</template>
                        </span>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>{{ item.subscriberName }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-chip
                        :color="statusColors[item.status]"
                        text-color="white"
                        class="chip-fluid"
                      >{{ item.status }}</v-chip>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider v-bind:key="'d'+index"></v-divider>
                </template>
              </v-list>
              <div class="mt-3 text-xs-center" v-if="totalPages>1">
                <v-pagination
                  v-model="currentPage"
                  @input="changePage"
                  :length="totalPages"
                  :total-visible="7"
                ></v-pagination>
              </div>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import Jazzicon from "../../components/Jazzicon";
import {
  INVITATIONS_STATUS_COLORS,
  INVITATIONS_STATUS_TYPES,
  INVITATIONS_SORT_TYPES
} from "../../libs/constants";

export default {
  data() {
    return {
      isLoading: false,
      isLoadingSubscribers: false,
      properties: [],

      statusColors: INVITATIONS_STATUS_COLORS,
      statusTypes: INVITATIONS_STATUS_TYPES,
      sortTypes: INVITATIONS_SORT_TYPES,

      // Default Values
      status: [
        "Pending",
        "In Process",
        "Waiting for Signature",
        "Signer Refused",
        "Coding",
        "Inactive",
        "Alert Blacklist",
      ],
      sort: "createdDate",
      sortAscending: false,

      searchSubscriber: null,
      subscribers: [],
      subscriberId: null,
      subscriberName: null,

      currentPage: 1,
      pageSize: 20,
      totalPages: null,
      total: null
    };
  },
  components: {
    Jazzicon
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    }),
    startResult() {
      return this.currentPage * this.pageSize - this.pageSize + 1;
    },
    endResult() {
      return (
        this.currentPage * this.pageSize +
        this.properties.length -
        this.pageSize
      );
    }
  },
  created() {
    this.checkCurrentLogin();
    if (!this.currentUser.isCompliance) {
      return this.$router.push("/properties");
    }
  },
  updated() {
    this.checkCurrentLogin();
  },
  watch: {
    searchSubscriber(val) {
      if (this.isLoadingSubscribers || !val || val.length < 3) {
        return;
      }

      const _this = this;
      this.isLoadingSubscribers = true;
      this.$http
        .get("/manager/subscribers/" + val)
        .then(response => {
          _this.subscribers = response.data || [];
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => {
          _this.isLoadingSubscribers = false;
        });
    },
    currentPage() {
      this.getMoreProperties();
    },
    subscriberId(id) {
      if (id) {
        let found = this.subscribers.find(item => {
          return item.id == id;
        });
        this.subscriberName = found.description;
      }
      this.getProperties();
    },
    status() {
      this.getProperties();
    },
    sort() {
      this.getProperties();
    },
    sortAscending() {
      this.getProperties();
    }
  },
  methods: {
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (
        !this.currentUser ||
        !this.currentUser.isLoggedIn ||
        !localStorage.token ||
        now >= this.currentUser.expire
      ) {
        this.$router.push("/login?redirect=" + this.$route.path);
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    },
    getProperties() {
      this.currentPage = 1;
      this.getMoreProperties();
    },
    getMoreProperties() {
      const _this = this;
      this.isLoading = true;
      this.$http
        .post("/manager/properties", {
          page: this.currentPage - 1,
          pageSize: this.pageSize,
          status: this.status,
          sort: this.sort,
          sortAscending: this.sortAscending,
          subscriberId: this.subscriberId
        })
        .then(response => {
          _this.properties = response.data.results || [];
          _this.totalPages = response.data.totalPages;
          _this.total = response.data.total;

          for (let property in _this.properties ) {
            _this.properties[property].statusUrl = '/compliance/documents/' + _this.properties[property].propertyId
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => (this.isLoading = false));
    },
    changePage(page) {
      this.currentPage = page;
      this.getMoreProperties();
      this.$scrollTo("#app");
    },
  },
  mounted() {
    this.getProperties();
  }
};
</script>
<style>
.page-invitations h4 {
  font-weight: 500;
  color: #2196f3;
}

.chip-fluid {
  width: 100%;
}

.page-invitations .v-list__tile__action {
  min-width: auto;
}

.page-invitations .filter-properties .v-list__tile__action {
  pointer-events: none;
}

.page-invitations .v-chip__content {
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.page-invitations .v-tabs__item {
  background: #efefef;
}

.page-invitations .v-tabs__item--active {
  background: #fff;
}

.page-invitations .v-tabs__slider-wrapper {
  top: 0;
  bottom: none;
}

.page-invitations .v-content__wrap {
  max-width: 100%;
}
</style>
